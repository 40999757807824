<template>
    <div class="pl-my-detail-wrap">
        <div class="pl-quote-status-box">
            <div class="pl-flex-start">
                <div class="pl-img-box">
                    <img :src="recordIcon" alt="" class="pl-record-img">
                </div>
                <p class="pl-status-text">{{ info.status == 0 ? '报价中' : '报价完成' }}</p>
            </div>
            <div class="pl-row">
                <span class="pl-label">报价单号:</span>
                <span>{{ info.sn || '--' }}</span>
            </div>
            <div class="pl-row">
                <span class="pl-label">制单人:</span>
                <span>{{ info.user.name || '' }}</span>
            </div>
            <div class="pl-row">
                <span class="pl-label">提交日期:</span>
                <span>{{ info.created_at }}</span>
            </div>
            <!-- <div class="pl-row">
                <span class="pl-label">完成日期:</span>
                <span>2020-03-03</span>
            </div> -->
        </div>
        <div class="module-title">我的报价单</div>
        <el-table :data="tableData" border class="pl-table" @selection-change="selectionChange" ref="multipleTable">
            <el-table-column label="全选" type="selection" width="55">
            </el-table-column>
            <el-table-column prop="goods_name" label="产品名称"></el-table-column>
            <el-table-column prop="production_model" label="产品型号"></el-table-column>
            <el-table-column prop="quantity" label="数量"></el-table-column>
            <el-table-column prop="price" label="单价"></el-table-column>
            <el-table-column prop="tax_price" label="单价(含税)"></el-table-column>
            <el-table-column prop="ship_days" label="发货天数"></el-table-column>
            <el-table-column prop="total_price" label="合计"></el-table-column>
            <el-table-column prop="remark" label="备注"></el-table-column>
            <el-table-column prop="penglong_remark" label="鹏隆备注"></el-table-column>

            <el-table-column prop="status" label="状态">
                <template slot-scope="scope">
                    <span>{{ scope.row.status == 0 ? '报价中' : '报价完成' }}</span>
                </template>
            </el-table-column>
        </el-table>
        <div class="pl-footer pl-flex">
            <div class="pl-footer-left">
                <span>已选择{{ selectIds.length }}件商品</span>
                <span>总价：</span>
                <span class="pl-money">￥{{ totalPrice }}</span>
            </div>
            <div class="pl-btn-box">
                <!-- <el-button type="primary" plain @click="goShopping">打包进购物车</el-button> -->
                <el-button type="primary" @click="goOrder" :disabled="!selectIds.length">转换成订单</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import { getQuotation, storeOrder } from '@/api/qoute.js'
import { addShopingCart } from '@/api/shoping.js'
import recordIcon from '@/assets/images/user/record1.png'
export default {
    data() {
        return {
            recordIcon,
            id: this.$route.params.id,
            tableData: [],
            pages: {
                per_page: 20,
                page: 1,
                total: 0
            },
            selectIds: [],
            totalPrice: 0,
            info: { user: {} }
        }
    },
    methods: {
        goShopping() {
            let data = this.tableData[0]




            addShopingCart({
                goods_id: data.goods_id, production_model: data.production_model, quantity: data.quantity,
                price: data.price, tax_price: data.tax_price, total_price: data.total_price
            })
            // this.$router.push('/user/shopping')
        },
        goOrder() {
            if (!this.selectIds.length) return false
            storeOrder({ quotation_order_goods_ids: this.selectIds }).then(res => {
                this.$router.push({ path: '/user/shopping/order', query: res || {} })
            })
            // this.$router.push('/user/shopping/order')
        },
        getQuotation() {
            if (!this.id) return false
            getQuotation(this.id).then(res => {
                this.info = res || {}
                this.tableData = res.order_goods || []
            })
        },
        selectionChange(args) {
            let ids = []
            let total = 0
            let total_price = ''
            args.forEach(item => {
                ids.push(item.id)
                total = item.total_price * 1 + total
            })
            this.selectIds = ids
            total_price = total.toString()
            let index = total_price.indexOf('.')
            if (index !== -1) {
                this.totalPrice = total_price.substr(0, index + 3)
                return ''
            }
            this.totalPrice = total_price
        }
    },
    created() {
        this.getQuotation()
    }

}
</script>
<style lang="scss" scoped>
.pl-my-detail-wrap {
    .pl-quote-status-box {
        width: 100%;
        padding: 30px;
        background: #fff;
    }

    .pl-img-box {
        width: 50px;
        height: 50px;
        padding: 5px 5px 5px 10px;
        line-height: 59px;
        text-align: center;
        border-radius: 50%;
        background: #f1d6d6;

        img {
            width: 80%;
        }
    }

    .pl-status-text {
        color: #D9262C;
        font-size: 16px;
        padding-left: 15px;
        font-weight: 600;
    }

    .pl-row {
        padding: 5px 0 5px 65px;
        font-size: 14px;

        .pl-label {
            color: #999696;
            padding-right: 15px;
        }
    }

    .module-title {
        margin: 15px 0 20px;
    }

    .pl-table {
        margin-bottom: 20px;
    }

    .pl-footer {
        .pl-footer-left {
            color: #999696;
            font-size: 14px;

            span:nth-of-type(1) {
                padding-right: 15px;
            }

            .pl-money {
                color: rgb(219, 85, 85);
                font-size: 18px;
                font-weight: 600;
            }
        }
    }
}
</style>
